




























import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { CategorySelectionStepModel, EnergySelection } from '@/models'
import { State } from 'vuex-class'

@Component({
  name: 'energy-selector',
})
export default class EnergySelector extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: false }) public readonly value!: EnergySelection | null
  @Prop({ type: Array, required: true }) public readonly energies!: string[]
  //#endregion

  //#region [Data]
  @State('category', { namespace: 'complaint/categorySelection' })
  public categorySelectionState!: CategorySelectionStepModel | null

  public energySelected: string | null = null
  //#endregion

  //#region [Watch]
  @Watch('value')
  public onValueChanged(newVal: EnergySelection | null) {
    if (newVal) {
      if (newVal.electricity) {
        this.energySelected = 'electricity'
      } else if (newVal.gas) {
        this.energySelected = 'gas'
      } else if (newVal.electricityAndGas) {
        this.energySelected = 'electricityAndGas'
      } else if (newVal.streetlight) {
        this.energySelected = 'streetlight'
      } else if (newVal.notAvailable) {
        this.energySelected = 'notAvailable'
      }
    } else {
      this.energySelected = null
    }
  }

  @Watch('energySelected')
  public onEnergySelectedChanged(newVal: string | null) {
    this.change(
      newVal === 'electricity',
      newVal === 'gas',
      newVal === 'electricityAndGas',
      newVal === 'streetlight',
      newVal === 'notAvailable',
    )
  }

  //#endregion

  //#region [Emit]
  @Emit('input')
  public change(
    electricity: boolean,
    gas: boolean,
    electricityAndGas: boolean,
    streetlight: boolean,
    notAvailable: boolean): EnergySelection {
    return { electricity, gas, electricityAndGas, streetlight, notAvailable }
  }

  //#endregion

  //#region [Computed]
  public mounted() {
    this.onValueChanged(this.value)
  }

  //#endregion
}
